import React from 'react';
import './project-menu.css';
import { Link, StaticQuery } from 'gatsby';
import { graphql } from 'gatsby';

const query = graphql`
  query {
    allWpPage(filter: { uri: { regex: "/plus/(.+)/" } }) {
      edges {
        node {
          status
          uri
          title
          slug
          menuOrder
          parentId
          id
          guid
        }
      }
    }
  }
`;

function isPublishedPlusProjekt() {
  return (edge) =>
    edge?.node?.status === 'publish' &&
    edge?.node?.uri?.includes('plus/') &&
    (edge?.node?.uri?.match(/\//gi) || []).length > 3 &&
    edge?.node?.slug !== 'plus';
}

function byMenuOrder() {
  return (a, b) =>
    (a?.node?.menuOrder || 0) > (b?.node?.menuOrder || 0) ? 1 : -1;
}

type ListLinkItemProps = {
  title: string;
  href: string;
  listStyleClass?: string;
  linkStyleClass?: string;
  children?: React.ReactNode;
};

function ListLinkItem(props: ListLinkItemProps): JSX.Element {
  // console.log(props);
  const { title, href, listStyleClass, linkStyleClass, children } = props;

  return (
    <li className={listStyleClass}>
      <Link to={href} className={linkStyleClass}>
        {title}
      </Link>
      {children}
    </li>
  );
}

/**
 * Build html elements for all the menue items and their children,
 * the element belonging to the active page will be bolder and a bit large
 * in comparison to the others.
 * @param edge
 * @param index
 * @param currentPageId
 */
function createLinkListItem(edge, index, currentPageId: number): JSX.Element {
  const isActive = currentPageId === edge.node.wordpress_id;
  return (
    <ListLinkItem
      key={edge.node.id}
      title={edge.node.title}
      href={edge.node.uri}
      listStyleClass="project-menu__item"
      linkStyleClass={`project-menu__link ${
        isActive ? 'active-project-menu' : ''
      } `}
    />
  );
}

export interface PlusProjektMenuProps {
  pageId: number;
}

/**
 * Build side menue entries for all `Projekte` pages and their children
 * @param props
 * @constructor
 */
function PlusProjektMenu({ pageId }: PlusProjektMenuProps): JSX.Element {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <div className="project-menu__container">
          <ul className="project-menu">
            {data.allWpPage.edges
              .filter(isPublishedPlusProjekt())
              .sort(byMenuOrder())
              .map((edge, index) => {
                // only collect the archive pages <here, we add a menu item for
                // them later
                return createLinkListItem(edge, index, pageId);
              })}
          </ul>
        </div>
      )}
    />
  );
}

export default PlusProjektMenu;
