import BasicStructure from '../../components/basic-structure/basic-structure';
import React from 'react';
import TitleMetaTags from '../../components/title-meta-tags/title-meta-tags';
import ContentContainer from '../../components/content-container/content-container';
import { graphql, Page } from 'gatsby';
import { getWpBlock } from '../../components/wp-blocks';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Menubar from '../../components/menu/menubar';
import BurgerMenu from '../../components/burgermenu/burgermenu';
import Sidebar from '../../components/sidebar/sidebar';
import PlusProjektMenu from '../../components/side-bar-components/plus-menu';
import ScrollUp from '../../components/scroll-up/scroll-up';

interface Props {
  data: Data;
  pageContext: PageContext;
}

interface Data {
  wpPage: any;
}

interface PageContext {
  categoryTitle: string;
  subcategorySlug: string;
  subcategoryTitle: string;
  pageId: number;
}

export default function PlusProjekt(props: Props): JSX.Element {
  const page = props.data?.wpPage;
  const blocks = page?.blocks || [];

  const { categoryTitle, subcategorySlug, subcategoryTitle, pageId } =
    props.pageContext;

  return (
    <BasicStructure>
      <TitleMetaTags title={subcategoryTitle} />
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu>
          <PlusProjektMenu pageId={pageId} />
        </BurgerMenu>
      </Header>
      <Sidebar>
        <PlusProjektMenu pageId={pageId} />
        <ScrollUp />
      </Sidebar>
      <ContentContainer>
        <h1 className="category-title">{categoryTitle}</h1>
        <section className="plus-project-page-content">
          {blocks.length > 0 &&
            blocks.map((block, index) => {
              const WpBlock = getWpBlock(block.__typename);

              if (!WpBlock) return null;

              const blockKey = `page-${page.id}-block-${index}`;

              return (
                <div key={blockKey}>
                  <WpBlock block={block} />
                </div>
              );
            })}
        </section>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export const pageQuery = graphql`
  query ($pageId: String!) {
    wpPage(id: { eq: $pageId }) {
      id
      title
      uri
      databaseId
      parentDatabaseId
      blocks {
        __typename
        dynamicContent
        isDynamic
        originalContent
        saveContent
        ...WPColumns
        ...WpCoreImageBlockFragment
        ...WpCoreGalleryBlockFragment
        #        ... on WpCoreEmbedYoutubeBlock {
        #          __typename
        #          originalContent
        #          attributes {
        #            ... on WpCoreEmbedYoutubeBlockAttributes {
        #              caption
        #              url
        #              #              className
        #              providerNameSlug
        #              type
        #            }
        #          }
        #        }
        innerBlocks {
          __typename
          saveContent
          isDynamic
          dynamicContent
          ...WpCoreImageBlockForGalleryFragment
          ...WpCoreGalleryBlockFragment
        }
      }
    }
  }
`;
